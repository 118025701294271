import './App.css';
import HomePage from './pages/HomePage'
import FirstPrice from './pages/FirstPrice'
import { Routes, Route } from 'react-router-dom'
import { AuthPage } from './pages/AuthPage';
import ReviewPage from './pages/ReviewPage';
import ProductPlacePage from './pages/PlaceCheker';


function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/login' element={<AuthPage />} />
        <Route path='/checker' element={<ProductPlacePage />} />
      </Routes>
    </>
  );
}

export default App;
