import React, { useState } from 'react';

function InputForm() {
    const [id, setId] = useState('');
    const [minutes, setMinutes] = useState('');
    const [statusMessage, setStatusMessage] = useState(''); // Сообщение о статусе
    const [buttonStatus, setButtonStatus] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestBody = {
            code: id,
            count: minutes
        };

        try {
            const response = await fetch('https://fp.nemo-monitoring.kz/api/v1/place-checker/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            const data = await response.json();

            if (data.status === 'success') {
                setStatusMessage('Успешно! Обновите страницу через несколько секунд, чтобы увидеть результаты.');
                setButtonStatus(true)
            } else {
                setStatusMessage('Ошибка. Пожалуйста, попробуйте снова.');
            }
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
            setStatusMessage('Ошибка при отправке данных. Пожалуйста, проверьте ваше соединение.');
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="flex justify-center items-center space-x-4 mb-4">
                <input
                    type="text"
                    placeholder="Введите SKU товара"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    className="p-2 border rounded-md"
                />
                <input
                    type="number"
                    placeholder="Количество минут"
                    value={minutes}
                    onChange={(e) => setMinutes(e.target.value)}
                    className="p-2 border rounded-md"
                />
                <button disabled={buttonStatus} type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                    Отправить
                </button>
            </form>
            {statusMessage && <p className="text-center">{statusMessage}</p>}
        </div>
    );
}

export default InputForm;
