import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CommentCard from '../components/CommentCard';
import { Label, TextInput, Button } from 'flowbite-react';
import Toasts from '../components/Toasts'
import ProductList from '../components/ProductList'
import InputForm from '../components/InputForm'
import { useSelector } from 'react-redux'
import { checkAuthorization } from '../services/AuthService';
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'


function CheckedList({ handleAddReviewsClick }) {
    const showToast = useSelector(state => state.toast.show)
    const isAuth = useSelector(state => state.auth.isAuth)
    const [products, setProducts] = useState({});
    const [url, setUrl] = useState();

    const apiUrl = `https://fp.nemo-monitoring.kz/api/v1/product-checked/`;
    // const apiUrl = `http://127.0.0.1:8000/api/v1/product-checked/`;

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            checkAuthorization()
        )
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    setProducts(data);
                }
                
            });

    }, [dispatch, checkAuthorization])

    if (isAuth === 'notAuth') {
        return <Navigate to='/login' />
    }

    return (
        <div >
            {showToast && <Toasts />}
            <div className="mb-8 mt-4">
                <InputForm />
            </div>
            <div className="min-h-screen p-10">
                <h1 className="text-3xl font-bold mb-8">Список товаров</h1>
                <div className="bg-white p-8 rounded-lg shadow-md">
                    <ProductList data={products} />
                </div>
            </div>
        </div>
    );
}

function ProductPlacePage() {
    const [selectedReviews, setSelectedReviews] = useState([]);

    const handleAddReviewsClick = () => {
        // TODO: добавить отмеченные отзывы в список
    };

    return (
        <div>
            <CheckedList handleAddReviewsClick={handleAddReviewsClick} />
        </div>
    );
}

export default ProductPlacePage;
