const localhost = 'https://fp.nemo-monitoring.kz'

const apiURL = "/api";

export const endpoint = `${localhost}${apiURL}`;
export const productListURL = `${endpoint}/v1/product/`;
export const productDetailURL = id => `${endpoint}/v1/product/${id}/`;
export const addToCartURL = `${endpoint}/add-to-cart/`;
export const LOGIN_URL= `https://fp.nemo-monitoring.kz/api/token/obtain`;
export const REGISTER_URL= `${endpoint}/token/register`;
export const checkAuthToken = `${endpoint}/token/verify/`;
export const productFilterURL = ids => `${endpoint}/v1/product/${ids}/`;
export const PRODUCT_SEARCH_FILTER_URL = filter => `${endpoint}/v1/product-filter/?search=${filter}&limit=5`;
export const PRODUCT_LIST_URL = params => `${endpoint}/v1/product-filter/?${params}`;
export const CATEGORIES = `${endpoint}/v1/category/`;
export const KASPI_OFFER_API_URL = () => `${endpoint}/v1/parse-kaspi-offer/`
export const PRODUCT_DETAIL_URL = code => `${endpoint}/v1/product/${code}/`
export const DETAIL_SETTING = () => `${endpoint}/v1/deatail-setting-info/`
export const SYNC_DATA = () => `${endpoint}/v1/sync-data/`
export const DOWNLOAD_STOCKS = () => `${endpoint}/manage/v1/export-stocks/`